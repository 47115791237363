export const BreadcrumbsAdvanced = () => import('../../components/bars/BreadcrumbsAdvanced.vue' /* webpackChunkName: "components/breadcrumbs-advanced" */).then(c => wrapFunctional(c.default || c))
export const BreadcrumbsBar = () => import('../../components/bars/BreadcrumbsBar.vue' /* webpackChunkName: "components/breadcrumbs-bar" */).then(c => wrapFunctional(c.default || c))
export const BreadcrumbsTitle = () => import('../../components/bars/BreadcrumbsTitle.vue' /* webpackChunkName: "components/breadcrumbs-title" */).then(c => wrapFunctional(c.default || c))
export const MainNavbar = () => import('../../components/bars/MainNavbar.vue' /* webpackChunkName: "components/main-navbar" */).then(c => wrapFunctional(c.default || c))
export const MainSidebar = () => import('../../components/bars/MainSidebar.vue' /* webpackChunkName: "components/main-sidebar" */).then(c => wrapFunctional(c.default || c))
export const NestedNavMenu = () => import('../../components/bars/NestedNavMenu.vue' /* webpackChunkName: "components/nested-nav-menu" */).then(c => wrapFunctional(c.default || c))
export const NestedSidebar = () => import('../../components/bars/NestedSidebar.vue' /* webpackChunkName: "components/nested-sidebar" */).then(c => wrapFunctional(c.default || c))
export const CardAuthorizationInfo = () => import('../../components/CardView/CardAuthorizationInfo.vue' /* webpackChunkName: "components/card-authorization-info" */).then(c => wrapFunctional(c.default || c))
export const CardChartGender = () => import('../../components/CardView/CardChartGender.vue' /* webpackChunkName: "components/card-chart-gender" */).then(c => wrapFunctional(c.default || c))
export const CardChatMessage = () => import('../../components/CardView/CardChatMessage.vue' /* webpackChunkName: "components/card-chat-message" */).then(c => wrapFunctional(c.default || c))
export const CardContactMessage = () => import('../../components/CardView/CardContactMessage.vue' /* webpackChunkName: "components/card-contact-message" */).then(c => wrapFunctional(c.default || c))
export const CardEvent = () => import('../../components/CardView/CardEvent.vue' /* webpackChunkName: "components/card-event" */).then(c => wrapFunctional(c.default || c))
export const CardFunFactCount = () => import('../../components/CardView/CardFunFactCount.vue' /* webpackChunkName: "components/card-fun-fact-count" */).then(c => wrapFunctional(c.default || c))
export const CardHero = () => import('../../components/CardView/CardHero.vue' /* webpackChunkName: "components/card-hero" */).then(c => wrapFunctional(c.default || c))
export const CardIndividualConnection = () => import('../../components/CardView/CardIndividualConnection.vue' /* webpackChunkName: "components/card-individual-connection" */).then(c => wrapFunctional(c.default || c))
export const CardInvestor = () => import('../../components/CardView/CardInvestor.vue' /* webpackChunkName: "components/card-investor" */).then(c => wrapFunctional(c.default || c))
export const CardInvestorMember = () => import('../../components/CardView/CardInvestorMember.vue' /* webpackChunkName: "components/card-investor-member" */).then(c => wrapFunctional(c.default || c))
export const CardMentor = () => import('../../components/CardView/CardMentor.vue' /* webpackChunkName: "components/card-mentor" */).then(c => wrapFunctional(c.default || c))
export const CardNoItem = () => import('../../components/CardView/CardNoItem.vue' /* webpackChunkName: "components/card-no-item" */).then(c => wrapFunctional(c.default || c))
export const CardNoNotification = () => import('../../components/CardView/CardNoNotification.vue' /* webpackChunkName: "components/card-no-notification" */).then(c => wrapFunctional(c.default || c))
export const CardSaveListItem = () => import('../../components/CardView/CardSaveListItem.vue' /* webpackChunkName: "components/card-save-list-item" */).then(c => wrapFunctional(c.default || c))
export const CardStakeholderConnection = () => import('../../components/CardView/CardStakeholderConnection.vue' /* webpackChunkName: "components/card-stakeholder-connection" */).then(c => wrapFunctional(c.default || c))
export const CardStakeholderCounter = () => import('../../components/CardView/CardStakeholderCounter.vue' /* webpackChunkName: "components/card-stakeholder-counter" */).then(c => wrapFunctional(c.default || c))
export const CardStakeholderCounterLight = () => import('../../components/CardView/CardStakeholderCounterLight.vue' /* webpackChunkName: "components/card-stakeholder-counter-light" */).then(c => wrapFunctional(c.default || c))
export const CardStakeholderCounterSimple = () => import('../../components/CardView/CardStakeholderCounterSimple.vue' /* webpackChunkName: "components/card-stakeholder-counter-simple" */).then(c => wrapFunctional(c.default || c))
export const CardStartup = () => import('../../components/CardView/CardStartup.vue' /* webpackChunkName: "components/card-startup" */).then(c => wrapFunctional(c.default || c))
export const CardStartupBanner = () => import('../../components/CardView/CardStartupBanner.vue' /* webpackChunkName: "components/card-startup-banner" */).then(c => wrapFunctional(c.default || c))
export const CardStartupBannerFlat = () => import('../../components/CardView/CardStartupBannerFlat.vue' /* webpackChunkName: "components/card-startup-banner-flat" */).then(c => wrapFunctional(c.default || c))
export const CardStartupOutlined = () => import('../../components/CardView/CardStartupOutlined.vue' /* webpackChunkName: "components/card-startup-outlined" */).then(c => wrapFunctional(c.default || c))
export const CardUserConnection = () => import('../../components/CardView/CardUserConnection.vue' /* webpackChunkName: "components/card-user-connection" */).then(c => wrapFunctional(c.default || c))
export const CardViewBlog = () => import('../../components/CardView/CardViewBlog.vue' /* webpackChunkName: "components/card-view-blog" */).then(c => wrapFunctional(c.default || c))
export const CardViewESOMember = () => import('../../components/CardView/CardViewESOMember.vue' /* webpackChunkName: "components/card-view-e-s-o-member" */).then(c => wrapFunctional(c.default || c))
export const CardViewESONotableStartup = () => import('../../components/CardView/CardViewESONotableStartup.vue' /* webpackChunkName: "components/card-view-e-s-o-notable-startup" */).then(c => wrapFunctional(c.default || c))
export const CardViewESOProgram = () => import('../../components/CardView/CardViewESOProgram.vue' /* webpackChunkName: "components/card-view-e-s-o-program" */).then(c => wrapFunctional(c.default || c))
export const CardViewESOVertical = () => import('../../components/CardView/CardViewESOVertical.vue' /* webpackChunkName: "components/card-view-e-s-o-vertical" */).then(c => wrapFunctional(c.default || c))
export const CardViewEsoBannerFlat = () => import('../../components/CardView/CardViewEsoBannerFlat.vue' /* webpackChunkName: "components/card-view-eso-banner-flat" */).then(c => wrapFunctional(c.default || c))
export const CardViewEsoCompact = () => import('../../components/CardView/CardViewEsoCompact.vue' /* webpackChunkName: "components/card-view-eso-compact" */).then(c => wrapFunctional(c.default || c))
export const CardViewEvent = () => import('../../components/CardView/CardViewEvent.vue' /* webpackChunkName: "components/card-view-event" */).then(c => wrapFunctional(c.default || c))
export const CardViewEventSM = () => import('../../components/CardView/CardViewEventSM.vue' /* webpackChunkName: "components/card-view-event-s-m" */).then(c => wrapFunctional(c.default || c))
export const CardViewFeaturedOne = () => import('../../components/CardView/CardViewFeaturedOne.vue' /* webpackChunkName: "components/card-view-featured-one" */).then(c => wrapFunctional(c.default || c))
export const CardViewFeaturedTwo = () => import('../../components/CardView/CardViewFeaturedTwo.vue' /* webpackChunkName: "components/card-view-featured-two" */).then(c => wrapFunctional(c.default || c))
export const CardViewInfo = () => import('../../components/CardView/CardViewInfo.vue' /* webpackChunkName: "components/card-view-info" */).then(c => wrapFunctional(c.default || c))
export const CardViewInvestor = () => import('../../components/CardView/CardViewInvestor.vue' /* webpackChunkName: "components/card-view-investor" */).then(c => wrapFunctional(c.default || c))
export const CardViewInvestorCompact = () => import('../../components/CardView/CardViewInvestorCompact.vue' /* webpackChunkName: "components/card-view-investor-compact" */).then(c => wrapFunctional(c.default || c))
export const CardViewInvestorVertical = () => import('../../components/CardView/CardViewInvestorVertical.vue' /* webpackChunkName: "components/card-view-investor-vertical" */).then(c => wrapFunctional(c.default || c))
export const CardViewMentor = () => import('../../components/CardView/CardViewMentor.vue' /* webpackChunkName: "components/card-view-mentor" */).then(c => wrapFunctional(c.default || c))
export const CardViewNetworkInfo = () => import('../../components/CardView/CardViewNetworkInfo.vue' /* webpackChunkName: "components/card-view-network-info" */).then(c => wrapFunctional(c.default || c))
export const CardViewNetworkProfile = () => import('../../components/CardView/CardViewNetworkProfile.vue' /* webpackChunkName: "components/card-view-network-profile" */).then(c => wrapFunctional(c.default || c))
export const CardViewProgram = () => import('../../components/CardView/CardViewProgram.vue' /* webpackChunkName: "components/card-view-program" */).then(c => wrapFunctional(c.default || c))
export const CardViewProgramDetail = () => import('../../components/CardView/CardViewProgramDetail.vue' /* webpackChunkName: "components/card-view-program-detail" */).then(c => wrapFunctional(c.default || c))
export const CardViewPublicESOProgram = () => import('../../components/CardView/CardViewPublicESOProgram.vue' /* webpackChunkName: "components/card-view-public-e-s-o-program" */).then(c => wrapFunctional(c.default || c))
export const CardViewRecentHighlight = () => import('../../components/CardView/CardViewRecentHighlight.vue' /* webpackChunkName: "components/card-view-recent-highlight" */).then(c => wrapFunctional(c.default || c))
export const CardViewResourceFile = () => import('../../components/CardView/CardViewResourceFile.vue' /* webpackChunkName: "components/card-view-resource-file" */).then(c => wrapFunctional(c.default || c))
export const CardViewSpotLight = () => import('../../components/CardView/CardViewSpotLight.vue' /* webpackChunkName: "components/card-view-spot-light" */).then(c => wrapFunctional(c.default || c))
export const CardViewStakeholder = () => import('../../components/CardView/CardViewStakeholder.vue' /* webpackChunkName: "components/card-view-stakeholder" */).then(c => wrapFunctional(c.default || c))
export const CardViewStartup = () => import('../../components/CardView/CardViewStartup.vue' /* webpackChunkName: "components/card-view-startup" */).then(c => wrapFunctional(c.default || c))
export const CardViewStartupMember = () => import('../../components/CardView/CardViewStartupMember.vue' /* webpackChunkName: "components/card-view-startup-member" */).then(c => wrapFunctional(c.default || c))
export const CardViewStartupPage = () => import('../../components/CardView/CardViewStartupPage.vue' /* webpackChunkName: "components/card-view-startup-page" */).then(c => wrapFunctional(c.default || c))
export const CardViewStartupReturn = () => import('../../components/CardView/CardViewStartupReturn.vue' /* webpackChunkName: "components/card-view-startup-return" */).then(c => wrapFunctional(c.default || c))
export const CardViewStartupVertical = () => import('../../components/CardView/CardViewStartupVertical.vue' /* webpackChunkName: "components/card-view-startup-vertical" */).then(c => wrapFunctional(c.default || c))
export const CardViewStatistic = () => import('../../components/CardView/CardViewStatistic.vue' /* webpackChunkName: "components/card-view-statistic" */).then(c => wrapFunctional(c.default || c))
export const CardVisitorCounter = () => import('../../components/CardView/CardVisitorCounter.vue' /* webpackChunkName: "components/card-visitor-counter" */).then(c => wrapFunctional(c.default || c))
export const ChatList = () => import('../../components/CardView/ChatList.vue' /* webpackChunkName: "components/chat-list" */).then(c => wrapFunctional(c.default || c))
export const SimpleCard = () => import('../../components/CardView/SimpleCard.vue' /* webpackChunkName: "components/simple-card" */).then(c => wrapFunctional(c.default || c))
export const CardBarChart = () => import('../../components/Charts/CardBarChart.vue' /* webpackChunkName: "components/card-bar-chart" */).then(c => wrapFunctional(c.default || c))
export const CardBubbleChart = () => import('../../components/Charts/CardBubbleChart.vue' /* webpackChunkName: "components/card-bubble-chart" */).then(c => wrapFunctional(c.default || c))
export const CardColumnChart = () => import('../../components/Charts/CardColumnChart.vue' /* webpackChunkName: "components/card-column-chart" */).then(c => wrapFunctional(c.default || c))
export const CardColumnChart2 = () => import('../../components/Charts/CardColumnChart2.vue' /* webpackChunkName: "components/card-column-chart2" */).then(c => wrapFunctional(c.default || c))
export const CardHighlightedEvent = () => import('../../components/Charts/CardHighlightedEvent.vue' /* webpackChunkName: "components/card-highlighted-event" */).then(c => wrapFunctional(c.default || c))
export const CardPieChart = () => import('../../components/Charts/CardPieChart.vue' /* webpackChunkName: "components/card-pie-chart" */).then(c => wrapFunctional(c.default || c))
export const CardStakedColumnsChart = () => import('../../components/Charts/CardStakedColumnsChart.vue' /* webpackChunkName: "components/card-staked-columns-chart" */).then(c => wrapFunctional(c.default || c))
export const CardSwiperGrid = () => import('../../components/Charts/CardSwiperGrid.vue' /* webpackChunkName: "components/card-swiper-grid" */).then(c => wrapFunctional(c.default || c))
export const CardTreeMapChart = () => import('../../components/Charts/CardTreeMapChart.vue' /* webpackChunkName: "components/card-tree-map-chart" */).then(c => wrapFunctional(c.default || c))
export const PackedBubbleChart = () => import('../../components/Charts/PackedBubbleChart.vue' /* webpackChunkName: "components/packed-bubble-chart" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Common/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Highlight = () => import('../../components/Common/Highlight.vue' /* webpackChunkName: "components/highlight" */).then(c => wrapFunctional(c.default || c))
export const LandingEcosystem = () => import('../../components/Common/LandingEcosystem.vue' /* webpackChunkName: "components/landing-ecosystem" */).then(c => wrapFunctional(c.default || c))
export const LandingEvent = () => import('../../components/Common/LandingEvent.vue' /* webpackChunkName: "components/landing-event" */).then(c => wrapFunctional(c.default || c))
export const LandingPost = () => import('../../components/Common/LandingPost.vue' /* webpackChunkName: "components/landing-post" */).then(c => wrapFunctional(c.default || c))
export const LandingProgram = () => import('../../components/Common/LandingProgram.vue' /* webpackChunkName: "components/landing-program" */).then(c => wrapFunctional(c.default || c))
export const LandingWhatWeDo = () => import('../../components/Common/LandingWhatWeDo.vue' /* webpackChunkName: "components/landing-what-we-do" */).then(c => wrapFunctional(c.default || c))
export const MileStone = () => import('../../components/Common/MileStone.vue' /* webpackChunkName: "components/mile-stone" */).then(c => wrapFunctional(c.default || c))
export const NetworkSubNavbar = () => import('../../components/Common/NetworkSubNavbar.vue' /* webpackChunkName: "components/network-sub-navbar" */).then(c => wrapFunctional(c.default || c))
export const OnBoardingGuideline = () => import('../../components/Common/OnBoardingGuideline.vue' /* webpackChunkName: "components/on-boarding-guideline" */).then(c => wrapFunctional(c.default || c))
export const OurPartner = () => import('../../components/Common/OurPartner.vue' /* webpackChunkName: "components/our-partner" */).then(c => wrapFunctional(c.default || c))
export const OurTeam = () => import('../../components/Common/OurTeam.vue' /* webpackChunkName: "components/our-team" */).then(c => wrapFunctional(c.default || c))
export const PageHeader = () => import('../../components/Common/PageHeader.vue' /* webpackChunkName: "components/page-header" */).then(c => wrapFunctional(c.default || c))
export const PageHeaderUser = () => import('../../components/Common/PageHeaderUser.vue' /* webpackChunkName: "components/page-header-user" */).then(c => wrapFunctional(c.default || c))
export const Program = () => import('../../components/Common/Program.vue' /* webpackChunkName: "components/program" */).then(c => wrapFunctional(c.default || c))
export const RecentEvent = () => import('../../components/Common/RecentEvent.vue' /* webpackChunkName: "components/recent-event" */).then(c => wrapFunctional(c.default || c))
export const Testimonial = () => import('../../components/Common/Testimonial.vue' /* webpackChunkName: "components/testimonial" */).then(c => wrapFunctional(c.default || c))
export const VisitorView = () => import('../../components/Common/VisitorView.vue' /* webpackChunkName: "components/visitor-view" */).then(c => wrapFunctional(c.default || c))
export const DialogESOCohortCreate = () => import('../../components/dialogs/DialogESOCohortCreate.vue' /* webpackChunkName: "components/dialog-e-s-o-cohort-create" */).then(c => wrapFunctional(c.default || c))
export const DialogESOCohortEdit = () => import('../../components/dialogs/DialogESOCohortEdit.vue' /* webpackChunkName: "components/dialog-e-s-o-cohort-edit" */).then(c => wrapFunctional(c.default || c))
export const DialogESOMemberInvitation = () => import('../../components/dialogs/DialogESOMemberInvitation.vue' /* webpackChunkName: "components/dialog-e-s-o-member-invitation" */).then(c => wrapFunctional(c.default || c))
export const DialogESOProgramCreate = () => import('../../components/dialogs/DialogESOProgramCreate.vue' /* webpackChunkName: "components/dialog-e-s-o-program-create" */).then(c => wrapFunctional(c.default || c))
export const DialogESOProgramEditInfo = () => import('../../components/dialogs/DialogESOProgramEditInfo.vue' /* webpackChunkName: "components/dialog-e-s-o-program-edit-info" */).then(c => wrapFunctional(c.default || c))
export const DialogESOStartupInvitation = () => import('../../components/dialogs/DialogESOStartupInvitation.vue' /* webpackChunkName: "components/dialog-e-s-o-startup-invitation" */).then(c => wrapFunctional(c.default || c))
export const DialogGlobalSearch = () => import('../../components/dialogs/DialogGlobalSearch.vue' /* webpackChunkName: "components/dialog-global-search" */).then(c => wrapFunctional(c.default || c))
export const DialogPreviewHighlight = () => import('../../components/dialogs/DialogPreviewHighlight.vue' /* webpackChunkName: "components/dialog-preview-highlight" */).then(c => wrapFunctional(c.default || c))
export const DialogPreviewProgram = () => import('../../components/dialogs/DialogPreviewProgram.vue' /* webpackChunkName: "components/dialog-preview-program" */).then(c => wrapFunctional(c.default || c))
export const DialogStartupMemberInvitation = () => import('../../components/dialogs/DialogStartupMemberInvitation.vue' /* webpackChunkName: "components/dialog-startup-member-invitation" */).then(c => wrapFunctional(c.default || c))
export const DialogUserEditESOMember = () => import('../../components/dialogs/DialogUserEditESOMember.vue' /* webpackChunkName: "components/dialog-user-edit-e-s-o-member" */).then(c => wrapFunctional(c.default || c))
export const DialogUserEditStartupMember = () => import('../../components/dialogs/DialogUserEditStartupMember.vue' /* webpackChunkName: "components/dialog-user-edit-startup-member" */).then(c => wrapFunctional(c.default || c))
export const DialogUserFundingStageEdit = () => import('../../components/dialogs/DialogUserFundingStageEdit.vue' /* webpackChunkName: "components/dialog-user-funding-stage-edit" */).then(c => wrapFunctional(c.default || c))
export const DateRangePickerDialog = () => import('../../components/pickers/DateRangePickerDialog.vue' /* webpackChunkName: "components/date-range-picker-dialog" */).then(c => wrapFunctional(c.default || c))
export const ImagePicker = () => import('../../components/pickers/ImagePicker.vue' /* webpackChunkName: "components/image-picker" */).then(c => wrapFunctional(c.default || c))
export const ImagePickerTwo = () => import('../../components/pickers/ImagePickerTwo.vue' /* webpackChunkName: "components/image-picker-two" */).then(c => wrapFunctional(c.default || c))
export const EcosystemInvestor = () => import('../../components/sections/EcosystemInvestor.vue' /* webpackChunkName: "components/ecosystem-investor" */).then(c => wrapFunctional(c.default || c))
export const EcosystemMentor = () => import('../../components/sections/EcosystemMentor.vue' /* webpackChunkName: "components/ecosystem-mentor" */).then(c => wrapFunctional(c.default || c))
export const EcosystemProgramEvent = () => import('../../components/sections/EcosystemProgramEvent.vue' /* webpackChunkName: "components/ecosystem-program-event" */).then(c => wrapFunctional(c.default || c))
export const EcosystemStakeholder = () => import('../../components/sections/EcosystemStakeholder.vue' /* webpackChunkName: "components/ecosystem-stakeholder" */).then(c => wrapFunctional(c.default || c))
export const EcosystemStartup = () => import('../../components/sections/EcosystemStartup.vue' /* webpackChunkName: "components/ecosystem-startup" */).then(c => wrapFunctional(c.default || c))
export const FeaturedSlider = () => import('../../components/sections/FeaturedSlider.vue' /* webpackChunkName: "components/featured-slider" */).then(c => wrapFunctional(c.default || c))
export const AuthenticationForm = () => import('../../components/AuthenticationForm.vue' /* webpackChunkName: "components/authentication-form" */).then(c => wrapFunctional(c.default || c))
export const CardFundingStage = () => import('../../components/CardFundingStage.vue' /* webpackChunkName: "components/card-funding-stage" */).then(c => wrapFunctional(c.default || c))
export const LoadingCollapse = () => import('../../components/LoadingCollapse.vue' /* webpackChunkName: "components/loading-collapse" */).then(c => wrapFunctional(c.default || c))
export const SearchBar = () => import('../../components/SearchBar.vue' /* webpackChunkName: "components/search-bar" */).then(c => wrapFunctional(c.default || c))
export const SearchResultItem = () => import('../../components/SearchResultItem.vue' /* webpackChunkName: "components/search-result-item" */).then(c => wrapFunctional(c.default || c))
export const TableUserStartupFundingStage = () => import('../../components/TableUserStartupFundingStage.vue' /* webpackChunkName: "components/table-user-startup-funding-stage" */).then(c => wrapFunctional(c.default || c))
export const BannerMainPageHeader = () => import('../../components/Banner/MainPageHeader.vue' /* webpackChunkName: "components/banner-main-page-header" */).then(c => wrapFunctional(c.default || c))
export const ButtonPrimary = () => import('../../components/Button/ButtonPrimary.vue' /* webpackChunkName: "components/button-primary" */).then(c => wrapFunctional(c.default || c))
export const CustomEmbed = () => import('../../components/Custom/embed.vue' /* webpackChunkName: "components/custom-embed" */).then(c => wrapFunctional(c.default || c))
export const FormChatBar = () => import('../../components/Form/FormChatBar.vue' /* webpackChunkName: "components/form-chat-bar" */).then(c => wrapFunctional(c.default || c))
export const MenuNestedMenu = () => import('../../components/Menu/NestedMenu.vue' /* webpackChunkName: "components/menu-nested-menu" */).then(c => wrapFunctional(c.default || c))
export const InputsEditor = () => import('../../components/inputs/Editor.vue' /* webpackChunkName: "components/inputs-editor" */).then(c => wrapFunctional(c.default || c))
export const FormGetListedInvestorGetListedForm = () => import('../../components/Form/GetListed/InvestorGetListedForm.vue' /* webpackChunkName: "components/form-get-listed-investor-get-listed-form" */).then(c => wrapFunctional(c.default || c))
export const FormGetListedMentorGetListedForm = () => import('../../components/Form/GetListed/MentorGetListedForm.vue' /* webpackChunkName: "components/form-get-listed-mentor-get-listed-form" */).then(c => wrapFunctional(c.default || c))
export const FormGetListedStakeholderGetListedForm = () => import('../../components/Form/GetListed/StakeholderGetListedForm.vue' /* webpackChunkName: "components/form-get-listed-stakeholder-get-listed-form" */).then(c => wrapFunctional(c.default || c))
export const FormGetListedStartupGetListedForm = () => import('../../components/Form/GetListed/StartupGetListedForm.vue' /* webpackChunkName: "components/form-get-listed-startup-get-listed-form" */).then(c => wrapFunctional(c.default || c))
export const FormCreateInvestor = () => import('../../components/Form/Investor/FormCreateInvestor.vue' /* webpackChunkName: "components/form-create-investor" */).then(c => wrapFunctional(c.default || c))
export const FormEditInvestor = () => import('../../components/Form/Investor/FormEditInvestor.vue' /* webpackChunkName: "components/form-edit-investor" */).then(c => wrapFunctional(c.default || c))
export const FormEditInvestorMember = () => import('../../components/Form/Investor/FormEditInvestorMember.vue' /* webpackChunkName: "components/form-edit-investor-member" */).then(c => wrapFunctional(c.default || c))
export const FormInvestorAboutForm = () => import('../../components/Form/Investor/InvestorAboutForm.vue' /* webpackChunkName: "components/form-investor-about-form" */).then(c => wrapFunctional(c.default || c))
export const FormInvestorInbox = () => import('../../components/Form/Investor/InvestorInbox.vue' /* webpackChunkName: "components/form-investor-inbox" */).then(c => wrapFunctional(c.default || c))
export const FormInvestorTeamForm = () => import('../../components/Form/Investor/InvestorTeamForm.vue' /* webpackChunkName: "components/form-investor-team-form" */).then(c => wrapFunctional(c.default || c))
export const FormCreateMentor = () => import('../../components/Form/Mentor/FormCreateMentor.vue' /* webpackChunkName: "components/form-create-mentor" */).then(c => wrapFunctional(c.default || c))
export const FormEditMentor = () => import('../../components/Form/Mentor/FormEditMentor.vue' /* webpackChunkName: "components/form-edit-mentor" */).then(c => wrapFunctional(c.default || c))
export const FormEditPersonalInfo = () => import('../../components/Form/Personal/FormEditPersonalInfo.vue' /* webpackChunkName: "components/form-edit-personal-info" */).then(c => wrapFunctional(c.default || c))
export const FormPersonalProfileForm = () => import('../../components/Form/Personal/PersonalProfileForm.vue' /* webpackChunkName: "components/form-personal-profile-form" */).then(c => wrapFunctional(c.default || c))
export const FormReportProfile = () => import('../../components/Form/Report/FormReportProfile.vue' /* webpackChunkName: "components/form-report-profile" */).then(c => wrapFunctional(c.default || c))
export const FormCreateESO = () => import('../../components/Form/SEO/FormCreateESO.vue' /* webpackChunkName: "components/form-create-e-s-o" */).then(c => wrapFunctional(c.default || c))
export const FormEditESO = () => import('../../components/Form/SEO/FormEditESO.vue' /* webpackChunkName: "components/form-edit-e-s-o" */).then(c => wrapFunctional(c.default || c))
export const FormClaimStartupProfile = () => import('../../components/Form/Startup/FormClaimStartupProfile.vue' /* webpackChunkName: "components/form-claim-startup-profile" */).then(c => wrapFunctional(c.default || c))
export const FormCreateStartup = () => import('../../components/Form/Startup/FormCreateStartup.vue' /* webpackChunkName: "components/form-create-startup" */).then(c => wrapFunctional(c.default || c))
export const FormEditReturnStartup = () => import('../../components/Form/Startup/FormEditReturnStartup.vue' /* webpackChunkName: "components/form-edit-return-startup" */).then(c => wrapFunctional(c.default || c))
export const FormEditStartupInfo = () => import('../../components/Form/Startup/FormEditStartupInfo.vue' /* webpackChunkName: "components/form-edit-startup-info" */).then(c => wrapFunctional(c.default || c))
export const FormViewStartupProfile = () => import('../../components/Form/Startup/FormViewStartupProfile.vue' /* webpackChunkName: "components/form-view-startup-profile" */).then(c => wrapFunctional(c.default || c))
export const FormStartupFundingForm = () => import('../../components/Form/Startup/StartupFundingForm.vue' /* webpackChunkName: "components/form-startup-funding-form" */).then(c => wrapFunctional(c.default || c))
export const FormStartupInformationForm = () => import('../../components/Form/Startup/StartupInformationForm.vue' /* webpackChunkName: "components/form-startup-information-form" */).then(c => wrapFunctional(c.default || c))
export const FormStartupTeamForm = () => import('../../components/Form/Startup/StartupTeamForm.vue' /* webpackChunkName: "components/form-startup-team-form" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
